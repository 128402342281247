import { LANDING_PAGE_PATHS } from 'constants/landing-page';
import React from 'react';
import BaseLandingPage from 'style-book/landing-page/BaseLandingPage';
import SeoComponent from 'style-book/seo/SeoComponent';
import { lpServiceByPath } from 'utils/lp-utils';

import './index.css';

function OkrPage() {
  return <BaseLandingPage lpService={lpServiceByPath[LANDING_PAGE_PATHS.OKR]} />;
}

export default OkrPage;

export function Head() {
  const lpService = lpServiceByPath[LANDING_PAGE_PATHS.OKR];
  return (
    <SeoComponent
      title="Turn Your Data into Actionable Goals with goalskeeper.io"
      description="Transforming Data into Success: Goalskeeper's Powerful Solution. Set Goals, Visualize Insights, and Achieve Business Milestones."
      canonicalUrl="goalskeeper.io/okr/"
    />
  );
}
